'use strict';

import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {fsMagic} from './FsMagic.settings';
import {getRootStore} from '../../_react_/app.bootstrap';

export class FsController {
    constructor($stateParams, db, ROLES, currentUser) {
        'ngInject';

        this.stateHelper = getRootStore().stateHelper;
        this.stateParams = $stateParams;
        this.auth = getRootStore().authService;
        this.db = db;
        this.roles = ROLES;
        this.tsChatApi = getRootStore().chatApi;
        this.keepLogged = true;
        this.viewUserNameTranslate = getRootStore().localizationService.translate('FS.VIEW.USERNAME');
        this.viewPasswordTranslate = getRootStore().localizationService.translate('FS.VIEW.PASSWORD');
        this.loginSubmitButtonTranslate = getRootStore().localizationService.translate('FS.VIEW.LOGIN_SUBMIT_BUTTON');

        if (!currentUser) {
            this.isInLoginPage = true;
        }

        this.DASHBOARD_URL = TsUtils.replaceUrlDomain(DASHBOARD_URL, window.location.hostname);
    }

    submit(form) {
        if (!form.userName || !form.password) {
            // Please enter Username and password to continue
            this.loginMessage = getRootStore().localizationService.translate(
                'FS.CONTROLLER.ENTER_USERNAME_OR_PASSWORD'
            );

            return false;
        }

        this.auth
            .login(form.userName, form.password, this.keepLogged)
            .then(() => this._onLoginSuccess())
            .catch((err) => {
                const {accountId} = err;

                const {userId = 'none', roomId = 'none'} = getRootStore().chatApi;

                getRootStore().eventService.sendEventLog(userId, roomId, fsMagic.FS_LOGIN_FAIL);

                this.auth.logout(accountId, userId);
                this._errorMessage(err);
            });
    }

    _onLoginSuccess() {
        return this._checkPermissions().then(() => {
            this.tsChatApi.temporaryRoomCode = null;

            if (this.stateParams.g) {
                return this.stateHelper.safeGo('meeting');
            }

            const meta = {
                clientType: PlatformType.mobile_web,
                userId: getRootStore().authService.userId
            };

            const {userId = 'none', roomId = 'none'} = getRootStore().chatApi;

            getRootStore().eventService.sendEventLog(userId, roomId, fsMagic.FS_LOGIN_SUCCESS, meta);

            this.stateHelper.safeGo('start.main');
        });
    }

    _errorMessage(err) {
        if (err.status === 400 || err.status === 401 || err.status === 404) {
            this.loginMessage = getRootStore().localizationService.translate('FS.CONTROLLER.PLEASE_LOGIN');

            return;
        }

        if (err.status === 403) {
            this.loginMessage = getRootStore().localizationService.translate('FS.CONTROLLER.NOT_ALLOWED');

            return;
        }

        if (err.status === 500 || err.status === 502 || err.status === -1) {
            this.loginMessage = getRootStore().localizationService.translate('FS.CONTROLLER.BAD_GATEWAY');
        }
    }

    _checkPermissions() {
        return this._checkUserType().then((currentUser) => this._checkAccountSettings(currentUser));
    }

    _checkUserType() {
        return this.db.User.find('current').then((currentUser) => {
            const role = currentUser.role;

            if (role === this.roles.TECHNICIAN) {
                return Promise.resolve(currentUser);
            }

            return Promise.reject({
                userId: currentUser._id,
                accountId: currentUser.accountId,
                status: 403
            });
        });
    }

    _checkAccountSettings(currentUser) {
        return this.db.Account.find(currentUser.accountId, {
            bypassCache: true
        }).then((accountData) => {
            if (!accountData.protectedSettings || !accountData.protectedSettings.enableFieldServices) {
                throw new Error({status: 403});
            }
        });
    }
}
