import {action, computed, observable} from 'mobx';
import includes from 'lodash/includes';
import find from 'lodash/find';

import './ts-fullscreen-gallery.style.scss';

export const eventsEnum = {
    OPEN_GALLERY: 'OPEN_GALLERY',
    CLOSE_GALLERY: 'CLOSE_GALLERY'
};

export const contextEnum = {
    NEW_MESSAGE_RECEIVED: 'NEW_MESSAGE_RECEIVED',
    MESSAGE_CLICKED: 'MESSAGE_CLICKED',
    CLOSE_TOGGLE_CLICK: 'CLOSE_TOGGLE_CLICK',
    VIDEO_TOGGLE_CLICK: 'VIDEO_TOGGLE_CLICK',
    SET_COMPLETED: 'SET_COMPLETED'
};

export interface Message {
    data: any; // TODO: need to decide if this can be an object..
    setId?: boolean;
    stepIndex?: number;
    stepCount?: number;
    failed?: boolean;
    index?: number;
    isNew?: boolean;
    isVideo?: boolean;
    pending?: boolean;
    storageIndex?: number;
    type?: string;
    unread?: boolean;
    video?: string;
}

export interface IFullscreenGalleryController {
    openGallery: (eventArgs: {context: string; data: any; messages: Message[]}) => void;
    closeGallery: () => void;
    isSupportedMsg: (msg: Message) => boolean;
    filter: (messages: Message[]) => Message[];
    readonly isGalleryOpen: boolean;
    activeMessage: number;
}

export class FullscreenGalleryController implements IFullscreenGalleryController {
    private messages: Message[] = [];

    @observable private _isGalleryOpen: boolean = false;
    @observable private _activeMsg: number = 0;

    @action
    openGallery = (eventArgs: {context: string; data: any; messages: Message[]}) => {
        const {context, data, messages} = eventArgs;

        this.messages = messages;
        const filterData = this.filter(messages);
        let activeMessage = 0;

        if (context === contextEnum.NEW_MESSAGE_RECEIVED) {
            activeMessage = filterData.length - 1;
        } else if (context === contextEnum.MESSAGE_CLICKED) {
            const message =
                find(filterData, (msg) => msg.index === eventArgs.data) || filterData[filterData.length - 1];
            activeMessage = filterData.indexOf(message);
        } else if (context === contextEnum.SET_COMPLETED) {
            const message =
                find(filterData, (msg) => msg.index === eventArgs.data.index) || filterData[filterData.length - 1];
            activeMessage = filterData.indexOf(message);
        }

        this._isGalleryOpen = true;
        this._activeMsg = activeMessage;
    };

    @action
    closeGallery = () => {
        this._isGalleryOpen = false;
    };

    @computed
    get activeMessage(): number {
        return this._activeMsg;
    }

    @computed
    get isGalleryOpen(): boolean {
        return this._isGalleryOpen;
    }

    isSupportedMsg = (msg: Message) => {
        if (msg.type) {
            return includes(['IMG-DB', 'IMG-CL'], msg.type);
        }

        if (msg.data) {
            return msg.data.type === 'image';
        }

        return false;
    };

    filter = (messages: Message[] = []) => {
        return messages.filter((msg) => this.isSupportedMsg(msg));
    };
}
