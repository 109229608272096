import React from 'react';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {getRootStore} from '../../app.bootstrap';
import Translate from '../Translate';

import './ts-popup.scss';

const DEFAULT_WIDTH = 250;

interface ITsPopup {
    popupWidth?: number;
    isModal?: boolean;
    imgSrc?: string;
    title?: string;
    subTitle?: string;
    sideLabel?: string;
    textType?: string;
    textSrc?: string;
    keepTextField?: boolean;
    textPlaceholder?: string;
    btnOk?: string;
    btnCancel?: string;
    btnClose?: boolean;
    onClickedOk?: () => void;
    onClickedCancel?: () => void;
}

export const TsPopup: React.FC<ITsPopup> = ({
    popupWidth,
    isModal,
    imgSrc,
    title,
    subTitle,
    sideLabel,
    textType,
    textSrc,
    keepTextField,
    textPlaceholder,
    btnOk,
    btnCancel,
    btnClose,
    onClickedOk,
    onClickedCancel
}) => {
    const isFireFox = getRootStore().environmentDetect.isFF();
    const okButtonAria = getRootStore().localizationService.translate('END.VIEW.OK_BUTTON_ARIA');
    const cancelButtonAria = getRootStore().localizationService.translate('END.VIEW.CANCEL_BUTTON_ARIA');

    const isPercent = (value: string | number) => {
        return typeof value === 'string' && value.endsWith('%');
    };

    const widthCalc = () => {
        return popupWidth || DEFAULT_WIDTH;
    };

    const popupContainerStyle = (): React.CSSProperties => {
        if (!isModal) {
            return {};
        }

        const width = widthCalc();

        if (isPercent(width)) {
            return {height: '100%'};
        }

        return {
            top: '9%',
            left: `calc(50% - ${Number(width) / 2}px)`,
            maxWidth: `${width}px`
        };
    };

    const popupStyle = () => {
        const width = widthCalc();
        const minWidth = isPercent(width) ? width : `${width}px`;

        return {
            minWidth: minWidth,
            padding: '10px',
            justifyContent: 'center'
        };
    };

    const content = () => {
        return (
            <div className={'ts-popup'} style={popupStyle()}>
                <div className='ts-popup-image'>
                    {imgSrc && (
                        <img className={isFireFox ? 'ts-popup-img-ff' : ''} src={imgSrc} alt={'ts-popup-image'} />
                    )}
                </div>
                {sideLabel && (
                    <label>
                        <Translate text={sideLabel || ''}></Translate>
                    </label>
                )}
            </div>
        );
    };

    const buttons = () => {
        return (
            <div className='ts-popup-buttons'>
                {btnOk && onClickedOk && (
                    <button className='btn btn-secondary' aria-label={okButtonAria} onClick={onClickedOk}>
                        <Translate text={btnOk || ''}></Translate>
                    </button>
                )}

                {btnCancel && onClickedCancel && (
                    <button className='btn' aria-label={cancelButtonAria} onClick={onClickedCancel}>
                        <Translate text={btnCancel}></Translate>
                    </button>
                )}
            </div>
        );
    };

    return (
        <div>
            <GenericModal
                style={popupContainerStyle()}
                isCustomPosition={true}
                className={`ts-popup-container custom-position`}
                header={() => <React.Fragment />}
                content={() => content()}
                toolbar={() => (btnOk || btnCancel ? buttons() : <React.Fragment />)}
                isCloseVisible={false}
                onHideHandler={() => {}}
                show={true}></GenericModal>
        </div>
    );
};
