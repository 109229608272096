'use strict';

import get from 'lodash/get';
import assign from 'lodash/assign';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import find from 'lodash/find';

import {CAMERA_ACCESS, ROOM_MODES, STATUS_MESSAGES} from './meeting.settings.js';
import {UserType, FailureTriggerAction} from '@techsee/techsee-common/lib/constants/room.constants';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {
    MediaServiceType,
    SessionClientRole,
    SessionClientType
} from '@techsee/techsee-media-service/lib/MediaConstants';
import {MeetingEvent, ModeHandShakeFailReasons, ModeHandShakeErrorCodes} from './meeting.contracts';
import {getMeetingTracer} from './meeting.tracer';
import {MeetingState, stateByMode} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import {ErrorMessageCloseSessionController} from '../../components/error-message-close-session/controller';
import {OBSERVER_JOIN_ALERT_TIMEOUT} from './meeting.settings';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../_react_/app.bootstrap';

const trace = getMeetingTracer('MeetingController');

export const GEOLOCATION_DENIED_CODE = 1;

export class MeetingController {
    constructor(
        $window,
        $scope,
        $uibModal,
        roomInfo,
        currentUser,
        ROLES,
        db,
        $localStorage,
        $rootScope,
        tsChatHelper,
        $stateParams,
        mobileAppMediaService,
        audioService
    ) {
        'ngInject';

        this.tsTermsAndConditions = getRootStore().termsAndConditionsController;
        this.endMeetingModalController = getRootStore().endMeetingConfirmationController;
        this.lastConstructedMeetingMode = null;
        this.lastHandshakeSuccessState = null;
        this.mobileAppMediaService = mobileAppMediaService;
        this.meetingModeHandshakeFailed = this.meetingModeHandshakeFailed.bind(this);
        this.goToEndNew = this.goToEndNew.bind(this);
        this.syncMeeting = this.syncMeeting.bind(this);
        this.updateResources = this.updateResources.bind(this);
        this.browserUtilsService = getRootStore().browserUtilsService;
        this.audioService = audioService;
        this.$window = $window;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.stateHelper = getRootStore().stateHelper;
        this.chatApi = getRootStore().chatApi;
        this.chatHelper = tsChatHelper;
        this.networkInfo = getRootStore().networkInfo;
        this.$uibModal = $uibModal;
        this.mediaDevices = null;
        this.tsEnvironmentDetect = getRootStore().environmentDetect;
        this.currentUser = currentUser;
        this.roles = ROLES;
        this.db = db;
        this.$localStorage = $localStorage;
        this.isWebRTCEnabledAppleDevice = this.tsEnvironmentDetect.isWebRTCEnabledAppleDevice();
        this.isSafari = this.tsEnvironmentDetect.isSafari();
        this.isIOS = this.tsEnvironmentDetect.isIOS();
        this.roomId = roomInfo.roomId;
        this.roomCode = roomInfo.roomCode;
        this.intent = roomInfo.intent;
        this.allowOneClick = roomInfo.allowOneClick;
        this.mediaServiceType = roomInfo.mediaServiceType;
        this.referrer = roomInfo.referrer;
        this.usingApplication = roomInfo.usingApplication;
        this.isTurnServer = this.mediaServiceType === MediaServiceType.TURNSERVER;
        this.isMediaServer = this.mediaServiceType === MediaServiceType.MEDIASERVER;
        this.allowOneClick = this.allowOneClick === 'yes';
        this.isDesktopSharing = roomInfo.desktopSharing === 'yes';
        this.EventService = getRootStore().eventService;
        this.visibilityChange = getRootStore().visibilityChange;
        this.messageHistory = tsChatHelper.messageHistory;
        this.tsTranslationHelper = getRootStore().translationHelper;
        this.endParams = $stateParams.csi ? {csi: $stateParams.csi} : {};
        this.theme = $rootScope.THEME ? $rootScope.THEME : '';
        this.themeFolder = this.theme ? this.theme + '/' : '';
        this.defaultBranding = !this.theme || this.theme === 'in';
        this.brandingStyle = this.theme && this.theme !== 'in';
        this.brandingService = getRootStore().brandingService;
        this.brandingData = this.brandingService.getBrandingData();
        this.brandingLogo = this.brandingData?.companyLogoImage
            ? this.brandingData?.companyLogoImage
            : BASE_PATH + 'img/' + this.theme + '/logo.png';
        this.brandingSpinner = this.brandingData?.loader
            ? this.brandingData?.loader
            : BASE_PATH + 'img/' + this.theme + '/loader-spinner.gif';
        this.observerAlert = {
            color: '#0056D8',
            display: false,
            label: ''
        };

        if (this.isDesktopSharing) {
            this.errorMessageInDesktopSharing = new ErrorMessageCloseSessionController(
                this.endMeetingModalController.finishMeeting,
                this.endParams
            );
        }

        if (this.roomId) {
            this.db.Rooms.createInstance({_id: this.roomId}).setDeviceInfo(
                this.chatApi.browserInfo,
                false,
                !!this.roomCode,
                this.isDesktopSharing ? PlatformType.desktop_web : PlatformType.mobile_web,
                this.usingApplication
            );
        }

        this._sendMeetingEventLog(STATUS_MESSAGES.TECHSEE_MOBILE_LOADED);
        this._sendMeetingEventLog(LOG_EVENTS.userAgent, {
            browserInfo: this.chatApi.browserInfo,
            clientVersion: CLIENT_VERSION
        });

        this.db.Rooms.setReportedField(this.roomId, {data: {event: {key: 'techseeMobileLoaded', value: true}}});

        const deregisterVisibilityChanged = this.$rootScope.$on('dashboard:visibilityChanged', (event, data) => {
            // eslint-disable-next-line no-unused-vars
            const {param, newValue, oldValue} = data;

            $scope.$applyAsync();

            if (
                !this.isIOS &&
                newValue &&
                newValue !== oldValue &&
                get(this.chatApi, 'accountSettings.clientReloadOnDashboardFirstVisibility') &&
                !this.browserUtilsService.getFromSessionStorage('clientReloadOnDashboardFirstVisibility')
            ) {
                PromiseUtilsService.startPromiseWithTimeout(
                    () => trace.info(`dashboardVisible. newValue: ${newValue}, oldValue: ${oldValue} - reload`),
                    3000
                ).then(() => {
                    this.browserUtilsService.saveToSessionStorage('clientReloadOnDashboardFirstVisibility', true);

                    this.$window.location.reload();
                });
            }
        });

        this.$scope.$on('$destroy', deregisterVisibilityChanged);

        // enable manual location change blocking
        this.stateHelper.enable();
        this.syncMeetingModeConstruction();

        this._initSpeedTestResultsListener();

        // The intent fallback url has '&intent=no' added to it, to prevent
        // multiple attempts at loading it. If this param is found, we disable
        // launching intents on the browser-detect service
        const intentDisabled = this.intent === 'no';

        this.isReadyForSync = false;
        this.isCurrentDeviceSupported = false;
        getRootStore().browserDetect.run(
            // Keep work in the current browser
            (err, chromeRedirectionFailed) => {
                if (err) {
                    this.isCurrentDeviceSupported = false;
                    this.startMeeting();

                    return;
                }

                this.isCurrentDeviceSupported = true;

                const completeInitialization = () => {
                    if (this.roomId || this.roomCode) {
                        this.startMeeting();
                    } else {
                        this.stateHelper.safeGo('start.main', {postMeeting: true}, {reload: true});
                    }
                };

                if (chromeRedirectionFailed && (this.roomId || this.roomCode)) {
                    return this.db.Rooms.clientConnected({params: {roomId: this.roomId, roomCode: this.roomCode}})
                        .then((reply) => {
                            // If chrome redirection succeeded, no need to continue
                            const isClientConnected = reply.data;

                            if (isClientConnected) {
                                return this.goToEndNewWithTrace('goToEndNew on isClientConnected');
                            }

                            completeInitialization();
                        })
                        .catch(() => this.goToEndNewWithTrace('goToEndNew on chromeRedirectionFailed catch'));
                }

                completeInitialization();
            },

            // before redirection to chrome browser
            (next) => {
                this._sendMeetingEventLog(STATUS_MESSAGES.REDIRECTING_TO_CHROME)
                    .catch(() => null)
                    .finally(() => next());
            },
            this.referrer,
            intentDisabled
        );

        this.$scope.$watch(
            () => this.audioService.agentHasAudioStream(),
            () => {
                this.audioService.establishAudioStream();
                this.$rootScope.safeApply();
            }
        );
    }

    goToEndNewWithTrace(message) {
        const tracePromise = () => trace.info(message);

        return PromiseUtilsService.startPromiseWithTimeout(tracePromise, 3000).then(() => this.goToEndNew());
    }

    goToEndNew() {
        return this.stateHelper.safeGo('endNew', this.endParams);
    }

    startMeeting() {
        let platformType = PlatformType.mobile_web;

        if (this.isDesktopSharing) {
            platformType = PlatformType.desktop_web;
        }

        const initialSyncHandler = () => {
            this._sendMeetingEventLog(STATUS_MESSAGES.CLIENT_CONNECTED_TO_SOCKET, {calledFrom: 'Initial client sync'});

            if (!this.isCurrentDeviceSupported) {
                // TODO: No differentiation between device and browser unsupport
                return this._sendUnsupported(false);
            }

            this.EventService.setVerboseSettings(
                get(this.chatApi, 'accountSettings.verboseLogging'),
                get(this.currentUser, '_id') || 'none',
                get(this.chatApi, 'accountSettings.accountId') || 'none',
                this.roomId,
                this.roomCode
            );
            this.chatApi.setRoomNetworkInfo(this.networkInfo.connectionType, this.networkInfo.downlinkMax, this.roomId);
            this.initMeetingHandlers();

            Promise.resolve()
                .then(() => this.syncLanguage())
                .then(() => this.syncVideoSupport())
                .then(() => this.syncAudioSupport())
                .then(() => this.syncMode())
                .then(() => {
                    return this.syncModeHandshake().catch(this.meetingModeHandshakeFailed);
                })
                .then(() => (this.isReadyForSync = true))
                .then(() => this.syncMeeting())
                .catch((error) => {
                    this.isReadyForSync = true;
                    trace.info('StartMeeting error', error);
                    this._sendMeetingEventLog(STATUS_MESSAGES.SYNC_ERROR, {
                        side: platformType,
                        error: error && typeof error === 'string' ? error.toString() : JSON.stringify(error)
                    });
                });
        };

        this.chatApi.connect(this.roomId, UserType.client, this.roomCode, platformType).catch(() => {
            this.goToEndNewWithTrace('goToEndNew on this.chatApi.connect catch');
        });
        this.chatApi.once(socketEvents.CLIENT_IN.SYNC, initialSyncHandler);
        this.$scope.$on('$destroy', () => {
            this.chatApi.off(socketEvents.CLIENT_IN.SYNC, initialSyncHandler);
        });
    }

    async syncLanguage() {
        const lang = this.chatApi.accountSettings.clientLanguage || this.chatApi.accountSettings.language,
            prev = this.$localStorage.techseeClientLang,
            accountId = this.chatApi.accountSettings.accountId;

        if (prev !== lang) {
            this.$localStorage.techseeClientLang = lang;
        }

        await getRootStore().localizationService.changeLanguage(lang);

        return this.tsTranslationHelper
            .storeAccountCustomStrings(accountId)
            .then(() => {
                this._updateLang(lang, accountId);
            })
            .finally(() => Promise.resolve());
    }

    syncVideoSupport() {
        const currentState = stateByMode(this.chatApi.client.mode, true);

        //Skip detection if state still not known or video already proved not to work
        if (!currentState || this.chatApi.client.videoSupport === false) {
            return Promise.resolve();
        }

        // Skip detection if current mode is not requiring WebRTC or Camera.
        if (!currentState.webRtcRequired) {
            return Promise.resolve();
        }

        const {hasCamera, webRtcSupportInfo} = this.mobileAppMediaService.deviceSupportInfo;

        if (!this.webRTCLogWasSent) {
            this.webRTCLogWasSent = true;
            this._sendMeetingEventLog(STATUS_MESSAGES.WEBRTC_SUPPORTED, webRtcSupportInfo);
        }

        if (!currentState.cameraRequired) {
            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.VIDEO_SUPPORT, true);

            return Promise.resolve();
        }

        const canUseVideoModes = hasCamera && webRtcSupportInfo.isWebRTCSupported;

        this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.VIDEO_SUPPORT, canUseVideoModes);

        if (!canUseVideoModes) {
            this.chatApi.sendLog(STATUS_MESSAGES.VIDEO_IS_NOT_SUPPORTED);

            return Promise.resolve();
        }

        this.chatApi.sendLog(STATUS_MESSAGES.DETECTING_BROADCAST_ABILITY);

        return Promise.resolve();
    }

    syncAudioSupport() {
        if (!this.audioService.isAudioSupported) {
            trace.info('Audio is not supported');

            if (this.chatApi.dashboard.audioSupport && this.chatApi.client.audioSupport !== true) {
                this.chatApi.sendLog(STATUS_MESSAGES.AUDIO_IS_NOT_SUPPORTED);
            }

            return Promise.resolve();
        }

        this.audioService.setIfAudioEnable();
        this.$rootScope.safeApply();

        return Promise.resolve();
    }

    syncMode() {
        const {mode} = this.chatApi.client;

        if (!mode) {
            throw new Error('Unexpected scenario. On this stage the mode should be known');
        }

        const modeToGoTo = stateByMode(mode);
        const currentMode = get(this.stateHelper, '$state.current.name');

        return new Promise((resolve) => {
            if (currentMode === modeToGoTo) {
                trace.info(`No mode change required current: '${currentMode}', requested: '${modeToGoTo}'`);
                this.syncMeetingModeConstruction()
                    .then(resolve)
                    .catch((e) => {
                        trace.warn('Failed during syncMeetingModeConstruction', e);
                    });
            } else {
                trace.info(`syncMode transferring to mode ${modeToGoTo}. Current mode: ${currentMode || 'empty'}`);

                this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.PREPARING, true);

                // Android application has an issue that when:
                // 1. Switching from video to image
                // 2. Uploading an image
                // 3. Returning back to video
                // the video does not stream (video publisher reinitialized, but not hasVideo property event.
                // this is a workaround to refresh the video state. It could be improved by passing a param to the
                // state and let it refresh itself instead of using a timeout, but we invested too much in this workaround
                if (
                    stateByMode(ROOM_MODES.video) === currentMode &&
                    this.usingApplication &&
                    this.isTurnServer &&
                    this.imageSent
                ) {
                    this.imageSent = false;
                    setTimeout(() => this.$window.location.reload(), 5000);
                }

                if (
                    this.isDesktopSharing &&
                    currentMode === MeetingState.DesktopSharing &&
                    includes([ROOM_MODES.images, ROOM_MODES.oneClick], mode)
                ) {
                    this.chatApi.browserUtilsService.saveToSessionStorage('shouldEndSession', true);
                    this.errorMessageInDesktopSharing.show();

                    return resolve();
                }

                if (mode === ROOM_MODES.images) {
                    this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_SWITCHED_TO_IMAGE_UPLOAD);
                } else if (mode === ROOM_MODES.oneClick) {
                    this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_SWITCHED_TO_PHOTO);
                } else if (mode === ROOM_MODES.video) {
                    this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_SWITCHED_TO_VIDEOCHAT);
                } else if (mode === ROOM_MODES.coBrowsing) {
                    this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_SWITCHED_TO_COBROWSING);
                } else if (mode === ROOM_MODES.screen && !this.usingApplication) {
                    this.chatApi.sendLog(STATUS_MESSAGES.CUSTOMER_SWITCHED_TO_SCREENSHARE);
                } else if (mode === ROOM_MODES.screen && this.usingApplication) {
                    return resolve();
                }

                this.mobileAppMediaService.clearService().then(() => {
                    this.audioService.destroyAudioSubscriber();
                    this.stateHelper.safeGo(modeToGoTo);
                    this.syncMeetingModeConstruction().then(resolve);
                });
            }
        });
    }

    syncMeetingModeConstruction() {
        return new Promise((resolve) => {
            const currentMode = get(this.chatApi, 'client.mode');
            const requiredState = currentMode ? stateByMode(currentMode) : null;

            if (requiredState && this.lastConstructedMeetingMode === requiredState) {
                trace.info(`sync construction: ${requiredState} already constructed`);
                resolve();
            } else {
                trace.info(`${requiredState || 'mode is'} not constructed yet, waiting for construction event`);
                const offSyncModeConstruction = this.$rootScope.$on(
                    MeetingEvent.StateConstructionComplete,
                    (event, mode) => {
                        trace.info(`sync construction: ${mode} constructed now`);
                        offSyncModeConstruction();
                        this.lastConstructedMeetingMode = mode;
                        resolve();
                    }
                );

                this.$scope.$on('$destroy', offSyncModeConstruction);
            }
        });
    }

    syncModeHandshake() {
        return new Promise((resolve, reject) => {
            trace.info(
                `syncModeHandshake, this.lastConstructedMeetingMode: ${this.lastConstructedMeetingMode}, this.lastHandshakeSuccessState: ${this.lastHandshakeSuccessState}`
            );

            if (this.lastConstructedMeetingMode === this.lastHandshakeSuccessState) {
                trace.info('Handshake was already done for this state');

                return resolve();
            }

            trace.info('meeting handshake REQUEST');

            const offHandshakeSync = this.$rootScope.$on(MeetingEvent.StateHandShakeResult, (event, error) => {
                offHandshakeSync();
                if (error) {
                    trace.error('meeting handshake error', error);
                    reject(error);
                } else {
                    trace.info(
                        `meeting handshake success, this.lastConstructedMeetingMode: ${this.lastConstructedMeetingMode}, this.lastHandshakeSuccessState: ${this.lastHandshakeSuccessState}`
                    );
                    this.lastHandshakeSuccessState = this.lastConstructedMeetingMode;
                    resolve();
                }
            });

            this.$scope.$on('$destroy', offHandshakeSync);

            this.$rootScope.$emit(MeetingEvent.MeetingHandshakeRequest);
        });
    }

    syncLocation() {
        if (!this._detectLocation() && !get(this.chatApi, 'accountSettings.enableMobileGeolocation')) {
            return Promise.resolve();
        }

        this.$window.navigator.geolocation.getCurrentPosition(
            (pos) => {
                this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.LOCATION, {
                    lon: pos.coords.longitude,
                    lat: pos.coords.latitude,
                    altitude: pos.coords.altitude,
                    accuracy: pos.coords.accuracy
                });
                this.chatApi.sendLog(STATUS_MESSAGES.CLIENT_LOCATION);
            },
            (err) => {
                if (err.code === GEOLOCATION_DENIED_CODE) {
                    this.chatApi.sendLog(STATUS_MESSAGES.CLIENT_LOCATION_DENIED);
                } else {
                    this.chatApi.sendLog(STATUS_MESSAGES.CLIENT_LOCATION_ERROR);
                }
            }
        );

        return Promise.resolve();
    }

    syncVisibility() {
        const newState = this.visibilityChange.isVisible();
        const oldState = this.chatApi.client.visible;

        if (newState !== oldState) {
            // Needed since Chrome 56+ sends a visiblityChange event reporting
            // a hidden state, so on reloads we need to refresh the status
            this.chatApi.visibilityChanged(this.visibilityChange.isVisible());
        }

        this.mobileAppMediaService.setAutoReconnect(newState);
    }

    syncMediaSession() {
        trace.info('syncMediaSession');

        const mediaSessionModes = [
            ROOM_MODES.video,
            ROOM_MODES.screen,
            ROOM_MODES.appSharing,
            ROOM_MODES.videoApplication
        ];
        const sessionIsNeeded =
            this.mobileAppMediaService.isVoipEnabled || includes(mediaSessionModes, this.chatApi.client.mode);

        if (sessionIsNeeded && !this.mobileAppMediaService.isSessionActive && this.chatApi.areBothSidesConnected) {
            const currentState = stateByMode(get(this.chatApi, 'client.mode'), true);
            const isHandshakeSuccess = get(this.chatApi, 'client.' + currentState.handshakeProp);

            trace.info(
                `syncMediaSession - isHandshakeSuccess: ${isHandshakeSuccess}, this.lastHandshakeSuccessState: ${this.lastHandshakeSuccessState}, currentState: ${currentState?.state}`
            );

            if (!isHandshakeSuccess && this.lastHandshakeSuccessState !== currentState?.state) {
                trace.info('Handshake for current state is not success yet. Skipping connect to session');

                this.EventService.sendEventLog(
                    get(this.currentUser, '_id'),
                    this.chatApi.roomId || 'none',
                    LOG_EVENTS.skippingMediaSyncAsHandshakeFailedForCurrentState,
                    {
                        reason: `isHandshakeSuccess: ${isHandshakeSuccess}, this.lastHandshakeSuccessState: ${this.lastHandshakeSuccessState}, currentState: ${currentState?.state}`
                    }
                );

                return Promise.resolve();
            }

            let credentials = null;

            switch (this.mediaServiceType) {
                case MediaServiceType.TURNSERVER:
                    credentials = get(this.chatApi, 'accountSettings.turnServerConfig');
                    break;
                case MediaServiceType.MEDIASERVER:
                    credentials = assign({}, get(this.chatApi, 'dashboard.mediaServer.session'));
                    break;
                case MediaServiceType.OPENTOK:
                    credentials = assign({}, get(this.chatApi, 'dashboard.opentok.session'));
                    break;
                default:
                    throw new Error('Wrong media service type');
            }

            const mediaSessionParams = {
                sessionId: this.roomId,
                clientType: SessionClientType.INITIATOR,
                clientRole: SessionClientRole.USER,
                credentials,
                ipWhitelist: get(this.chatApi, 'accountSettings.useOnlyOpenTokAllowedIPS', false)
            };

            if (
                !mediaSessionParams.credentials ||
                Object.getOwnPropertyNames(mediaSessionParams.credentials).length < 2
            ) {
                trace.info(
                    'Credentials for media session are not arrived yet. Skipping connect to session',
                    mediaSessionParams.credentials
                );

                return Promise.resolve();
            }

            return this.mobileAppMediaService
                .connectToSession(mediaSessionParams)
                .then(() => {
                    trace.info('connected to media session');
                })
                .catch((error) => {
                    trace.error('error during connection to media session', error);
                });
        } else if (
            !sessionIsNeeded ||
            (this.mediaServiceType !== MediaServiceType.OPENTOK && !this.chatApi.areBothSidesConnected)
        ) {
            if (this.mobileAppMediaService.isSessionActive) {
                trace.info(
                    `Disconnecting from media session: isSessionNeeded - ${sessionIsNeeded}, areBothSidesConnected - ${this.chatApi.areBothSidesConnected}`
                );

                return this.mobileAppMediaService.disconnectFromSession();
            }
        }

        return Promise.resolve();
    }

    syncMeeting() {
        if (!this.chatApi.synced || !this.isReadyForSync) {
            trace.info(`syncMeeting not ready yet.
            received sync event from chatapi: ${this.chatApi.synced}. isReadyForSync: ${this.isReadyForSync}`);

            return;
        }

        trace.info('syncMeeting');
        if (!this.gotSynced) {
            this._sendMeetingEventLog(STATUS_MESSAGES.SYNC);
            this.gotSynced = true;
        }

        const oldInviteFlowDecision = this.chatApi.dashboard.meeting && !this.chatApi.client.tosRejected;
        const newInviteFlowDecision =
            this.chatApi.dashboard.meeting &&
            (!this.chatApi.client.tosRejected ||
                (this.chatApi.client.tosRejected && this.chatApi.client.isReviewingTOS));
        const isMeetingActive = this.chatApi.accountSettings.enableNewInvite
            ? newInviteFlowDecision
            : oldInviteFlowDecision;

        if (!isMeetingActive) {
            return this.chatApi
                .disconnect(true)
                .catch((error) => {
                    this.chatApi.sendLog(error);
                })
                .then(() => {
                    this.goToEndNewWithTrace('goToEndNew on !isMeetingActive');
                });
        }

        return Promise.resolve()
            .then(() => this.syncMode())
            .then(() => {
                return this.syncModeHandshake().catch(this.meetingModeHandshakeFailed);
            })
            .then(() => this.syncMediaSession())
            .then(() => this.syncVoipState())
            .then(() => this.syncVisibility())
            .then(() => {
                this.$rootScope.$emit(MeetingEvent.MeetingSyncRequest);
                trace.info('sending MeetingEvent.MeetingSyncRequest to mode controller');
            })
            .catch((error) => {
                trace.error('SyncMeetingError', error);
                this._sendMeetingEventLog(STATUS_MESSAGES.SYNC_ERROR, {
                    side: PlatformType.mobile_web,
                    error: error && typeof error === 'string' ? error.toString() : JSON.stringify(error)
                });
            });
    }

    syncVoipState() {
        if (this.audioService.isAllowAudio && !this.syncAudio) {
            this.syncAudio = true;
            this.audioService.initAudioAlert();
            this.$rootScope.safeApply();
        }

        if (!this.audioService.isAudioEnabled) {
            return Promise.resolve();
        }

        if (this.audioService.agentStream()) {
            return Promise.resolve();
        }

        const promiseAudioSubscriber = [this.audioService.createSubscriber()];

        if (get(this.chatApi, 'dashboard.observer.connected')) {
            promiseAudioSubscriber.push(this.audioService.observerConnected());
        }

        return Promise.all(promiseAudioSubscriber).catch((err) => trace.warn('Failed to create audio subscriber', err));
    }

    meetingModeHandshakeFailed(handshakeEventArgs) {
        const {reason, statusMessage, error} = handshakeEventArgs;

        trace.info('onMeetingModeHandshakeFailed', handshakeEventArgs);
        this.chatApi.sendLog(STATUS_MESSAGES.VIDEOSTREAM_FAILED);
        this._sendMeetingEventLog(statusMessage, error);

        if (reason === ModeHandShakeFailReasons.TERMS_REJECTED) {
            this.goToEndNewWithTrace('goToEndNew on reason TERMS_REJECTED');
        }

        if (reason === ModeHandShakeFailReasons.MEDIA_STREAM_FAILURE) {
            if (this.isDesktopSharing) {
                this.chatApi.browserUtilsService.saveToSessionStorage('shouldEndSession', true);
                this.errorMessageInDesktopSharing.show();

                if (error && error.errorCode === ModeHandShakeErrorCodes.PERMISSION_DENIED) {
                    this.db.Rooms.setReportedField(this.roomId, {
                        data: {
                            event: {
                                key: 'screenAccess',
                                value: CAMERA_ACCESS.CAMERA_REJECTED
                            }
                        }
                    });
                }

                return;
            }

            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.VIDEO_SUPPORT, false);
            this.chatApi.sendLog(STATUS_MESSAGES.UNABLE_TO_PUBLISH);
            this.chatApi.sendSwitchModeRequest(FailureTriggerAction.unableToPublish);
            this._sendMeetingEventLog(STATUS_MESSAGES.UNABLE_TO_PUBLISH);
            this.chatApi.cameraApprovalDialogStateChange(false);
            this.syncLocation();

            if (error && error.errorCode === ModeHandShakeErrorCodes.PERMISSION_DENIED) {
                this.db.Rooms.setReportedField(this.roomId, {
                    data: {
                        event: {
                            key: 'cameraAccess',
                            value: CAMERA_ACCESS.CAMERA_REJECTED
                        }
                    }
                });
            }
        }

        throw error;
    }

    initMeetingHandlers() {
        const roomRejectedListener = () => this.goToEndNewWithTrace('goToEndNew on roomRejectedListener'),
            timeoutListener = () => this.goToEndNewWithTrace('goToEndNew on timeoutListener'),
            joinRoomListener = (roomId) => (this.roomId = roomId),
            offlineTimeoutListener = () => {
                // normally a simple timeout will trigger a dashboard action,
                // but in an offline room the dashboard may not be connected
                if (this.chatApi.offlineRoom && !this.chatApi.dashboard.connected) {
                    this.goToEndNewWithTrace('goToEndNew on offlineRoom && !connected');
                }
            },
            imageSentListener = () => (this.imageSent = true),
            dashboardVisibleListener = (param, newValue, oldValue) =>
                this.$rootScope.$emit('dashboard:visibilityChanged', {param, newValue, oldValue}),
            appLauncherOpenLinkListener = (url) => {
                if (!url) {
                    return;
                }

                this.$window.open(url);
            };

        this.chatApi.on(socketEvents.CLIENT_IN.RELOAD_WHEN_VIDEO_FAILED, (newValue, oldValue) => {
            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.RELOAD_WHEN_VIDEO_FAILED, false);

            if (newValue && newValue !== oldValue) {
                PromiseUtilsService.startPromiseWithTimeout(
                    () => this._sendMeetingEventLog(LOG_EVENTS.clientReloadWhenVideoFailed),
                    3000
                ).then(() => this.$window.location.reload());
            }
        });

        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_AUDIO_HANDSHAKE_SUCCESS, () => {
            this.audioService.checkDashboardAudioHandshake();
            this.$rootScope.safeApply();
        });

        this.chatApi.on(socketEvents.CLIENT_IN.OBSERVER, (newValue) => {
            if (get(this.chatApi, 'accountSettings.enableObservation')) {
                const message = newValue.connected
                    ? 'MEETING.VIEW.OBSERVER_JOIN_THE_SESSION'
                    : 'MEETING.VIEW.OBSERVER_LEFT_THE_SESSION';

                if (newValue.connected) {
                    this.audioService.observerConnected();
                }

                this.observerAlert.display = true;
                this.observerAlert.label = getRootStore().localizationService.translate(message, {
                    user: `${newValue.firstName} ${newValue.lastName}`
                });

                clearTimeout(this.observerAlertTimeout);
                this.observerAlertTimeout = setTimeout(() => {
                    this.observerAlert.display = false;
                    this.observerAlertTimeout = null;
                }, OBSERVER_JOIN_ALERT_TIMEOUT);
            }
        });

        this.chatApi.on(socketEvents.CLIENT_IN.SYNC, this.syncMeeting);
        this.chatApi.on(socketEvents.CLIENT_IN.ICE_CREDENTIALS_CHANGED, (args) => {
            if (this.mobileAppMediaService.isSessionActive) {
                this.mobileAppMediaService.updateSessionCredentials(args);
            }
        });
        this.chatApi.on(socketEvents.CLIENT_IN.SOCKET_DISCONNECTED, () =>
            this.goToEndNewWithTrace('goToEndNew on chatApi socketDisconnected')
        );
        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.END_MEETING_ACTION, () =>
            this.goToEndNewWithTrace('goToEndNew on endMeetingAction')
        );
        this.chatApi.on(socketEvents.CLIENT_IN.CONNECTION_STATUS_CHANGED, this.syncMeeting);
        this.chatApi.on(socketEvents.CLIENT_IN.ROOM_REJECTED, roomRejectedListener);
        this.chatApi.on(socketEvents.CLIENT_IN.FORCE_TIMEOUT, timeoutListener);
        this.chatApi.on(socketEvents.CLIENT_IN.TIMEOUT, offlineTimeoutListener);
        this.chatApi.on(socketEvents.CLIENT_IN.JOIN_ROOM, joinRoomListener);
        this.chatApi.on(socketEvents.CLIENT_IN.RESOURCES_UPDATED, this.updateResources);
        this.chatApi.on(socketEvents.CLIENT_IN.IMAGE_SENT, imageSentListener);
        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_VISIBLE, dashboardVisibleListener);
        this.chatApi.on(socketEvents.CLIENT_IN.LOG, (log) => {
            if (log.name === 'MEDIA_PERMISSION_ALLOW') {
                this.syncLocation();
            }
        });

        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.APP_LAUNCHER_OPEN_LINK_ACTION, appLauncherOpenLinkListener);

        this.mobileAppMediaService.onSyncVoip(() => {
            this.syncAudio = false;
            this.syncVoipState();
        });

        this.$scope.$on('$destroy', () => {
            this.chatApi.off(socketEvents.CLIENT_IN_CHAT_API.END_MEETING_ACTION, () =>
                this.goToEndNewWithTrace('goToEndNew destroy off endMeetingAction')
            );
            this.chatApi.off(socketEvents.CLIENT_IN.SYNC, this.syncMeeting);
            this.chatApi.off(
                socketEvents.CLIENT_IN.ICE_CREDENTIALS_CHANGED,
                this.mobileAppMediaService.updateSessionCredentials
            );
            this.chatApi.off(socketEvents.CLIENT_IN.CONNECTION_STATUS_CHANGED, this.syncMeeting);
            this.chatApi.off(socketEvents.CLIENT_IN.ROOM_REJECTED, roomRejectedListener);
            this.chatApi.off(socketEvents.CLIENT_IN.FORCE_TIMEOUT, timeoutListener);
            this.chatApi.off(socketEvents.CLIENT_IN.TIMEOUT, offlineTimeoutListener);
            this.chatApi.off(socketEvents.CLIENT_IN.JOIN_ROOM, joinRoomListener);
            this.chatApi.off(socketEvents.CLIENT_IN.RESOURCES_UPDATED, this.updateResources);
            this.chatApi.off(socketEvents.CLIENT_IN.IMAGE_SENT, imageSentListener);
            this.chatApi.off(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_VISIBLE, dashboardVisibleListener);
            this.chatApi.off(
                socketEvents.CLIENT_IN_CHAT_API.APP_LAUNCHER_OPEN_LINK_ACTION,
                appLauncherOpenLinkListener
            );
            clearTimeout(this.observerAlertTimeout);
        });
    }

    get isEnabledNewInvite() {
        return get(this.chatApi, 'accountSettings.enableNewInvite');
    }

    _sendMeetingEventLog(type, meta) {
        return this.EventService.sendEventLog(get(this.currentUser, '_id'), this.roomId, type, meta);
    }

    _isTechnician() {
        const role = this.currentUser.role;

        return role === this.roles.TECHNICIAN || role === this.roles.TECHNICIAN_SUPERVISOR;
    }

    _detectLocation() {
        return (
            this._isTechnician() &&
            this.chatApi.accountSettings &&
            this.chatApi.accountSettings.allowJoinByMeetingCode &&
            this.chatApi.accountSettings.useLocation
        );
    }

    showWaitingScreen() {
        const isOnVideoChat =
            this.chatApi.client.mode === ROOM_MODES.video || this.chatApi.client.mode === ROOM_MODES.screen;

        // Don't show WFE when:
        //   1. Not on video chat
        //   2. Not being synced
        //   3. Using an offline room
        //   4. Meeting has been ended by agent
        if (
            !isOnVideoChat ||
            !this.chatApi.synced ||
            this.chatApi.offlineRoom ||
            !this.chatApi.dashboard.meeting ||
            this.chatApi.client.inCameraApprovalDialog ||
            this.chatApi.client.isReviewingTOS ||
            !this.chatApi.connected
        ) {
            return false;
        }

        // Show WFE when:
        //    1. Dashboard visibility is lost (i.e switched tabs). Enabled by suspendVideo toggle
        //    2. Dashboard is not connected to backend
        if (!this.chatApi.dashboard.connected) {
            return true;
        }

        return false;
    }

    showOfflineWaitingMessage() {
        const isOnVideoChat =
            this.chatApi.client.mode === ROOM_MODES.video || this.chatApi.client.mode === ROOM_MODES.screen;

        return isOnVideoChat && this.chatApi.offlineRoom && !this.chatApi.dashboard.connected;
    }

    updateResources(resources) {
        forEach(resources.data, (resource) => {
            const storageIndex = resource.storageIndex;
            const msg = find(this.messageHistory.messages, {storageIndex});

            if (!msg) {
                return;
            }

            if (msg.isVideo) {
                msg.data = resource.extraUrl;
                msg.video = resource.url;
            } else {
                msg.data = resource.url;
            }
        });
    }

    _sendUnsupported(isUnsupportedBrowser) {
        this.chatApi.sendLog(STATUS_MESSAGES.UNSUPPORTED_DEVICE);

        this._sendMeetingEventLog(STATUS_MESSAGES.UNSUPPORTED_DEVICE, {reason: 'device unsupported'});

        this.db.Rooms.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'supportedDevice',
                    value: false
                }
            }
        });

        if (!this.isEnabledNewInvite) {
            this.chatApi.disconnect();
        }

        return this.stateHelper.safeGo('unsupported', {isUnsupportedBrowser: isUnsupportedBrowser});
    }

    _initSpeedTestResultsListener() {
        const speedtestListener = (event) => {
            if (
                get(this.chatApi, 'accountSettings.mobileSpeedtestUrl', '').includes(event.origin) &&
                (get(this.chatApi, 'accountSettings.mobileSpeedtestOoklaPremium') ||
                    get(this.chatApi, 'accountSettings.mobileSpeedtestDefaultProvider'))
            ) {
                const {upload, download, config, latency} = event.data;

                const {serverId, testId} = config;

                // checking for number because the value for disabled param is something like string "n/a"
                const numUpload = Number(upload);
                const numDownload = Number(download);
                const numLatency = Number(latency && latency.minimum); // actually available when disabled, but can change in future

                this.db.Rooms.setSpeedtestResults(this.roomId, {
                    data: {
                        upload: isNaN(numUpload) ? undefined : numUpload,
                        download: isNaN(numDownload) ? undefined : numDownload,
                        latency: isNaN(numLatency) ? undefined : numLatency,
                        serverId: String(serverId), // unexpectedly speedtest started using a number instead of string
                        testId
                    }
                }).then((res) =>
                    this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.SPEED_TEST_RESULTS, res.data)
                );
            }
        };

        this.$window.addEventListener('message', speedtestListener);

        this.$scope.$on('$destroy', () => {
            this.$window.removeEventListener('message', speedtestListener);
        });
    }

    _updateLang(lang, accountId) {
        if (includes(['ar_AR', 'he_IL'], lang)) {
            this.$rootScope.LOCALE_DIR = 'rtl';
        } else {
            this.$rootScope.LOCALE_DIR = 'ltr';
        }

        this.$rootScope.LOCALE = lang;

        getRootStore().localizationService.setAccountData(accountId, lang);

        return getRootStore().localizationService.init();
    }

    displayReconnecting() {
        return (
            get(this.chatApi, 'dashboard.meeting') &&
            !get(this.chatApi, 'areBothSidesConnected') &&
            !get(this.chatApi, 'offlineRoom') &&
            !get(this.chatApi, 'client.isReviewingTOS')
        );
    }

    promptVideoDeviceLog() {
        // If there is no initial value yet, set an initial value
        this._promptLogInitialRequestId = this._promptLogInitialRequestId || Math.random();

        this.chatApi.sendLog(STATUS_MESSAGES.PROMPT_VIDEO_DEVICE, this._promptLogInitialRequestId);
        this.chatApi.cameraApprovalDialogStateChange(true);
    }
}
