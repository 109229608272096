'use strict';

import {ChatApiService} from '@techsee/techsee-chat-api/lib/services/ChatApiService';
import {
    ClosedByTypes,
    FailureTriggerAction,
    IRoom,
    SwitchModeReason,
    UserType
} from '@techsee/techsee-common/lib/constants/room.constants';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
// @ts-ignore
import {convertToSizeString, getFrontendUrl} from '@techsee/techsee-common/lib/utils';
import forEach from 'lodash/forEach';
import toPairs from 'lodash/toPairs';
import isEqual from 'lodash/isEqual';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';

import {STATUS_MESSAGES} from '../../../states/meeting/meeting.settings';
import get from 'lodash/get';
import includes from 'lodash/includes';
import {ITsNetworkInfo} from '../ts-network-info/ts-network-info.service';
import {ITsEventService} from '../ts-event-service/ts-event-service';
import {IVisibilityChange} from '../ts-visibility-change/ts-visibility-change.service';
import {DEFAULT_VIDEO_RESOLUTION} from '@techsee/techsee-media-service/lib/MediaConstants';
import {getMeetingTracer} from '../../../states/meeting/meeting.tracer';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../app.bootstrap';
import {IRoomService} from '@techsee/techsee-client-infra/lib/services/RoomService';

const trace = getMeetingTracer('MobileChatApi');

export class MobileChatApiService extends ChatApiService {
    public synced: any;

    public fsEnabled = false;

    public customerSurveyEnabled = false;

    public keepCustomerMobile = false;

    public enableSilentUploadTest = false;

    public offlineRoom = false;

    public enableSharing = false;

    public redirectUrl = '';

    public redirectType = '';

    public redirectDelayTime = 0;

    public customerSurveyMaxFeedbackRating = 4;

    public imageSharingQuality: number | undefined = undefined;

    public get currentRoomId() {
        return this.roomId;
    }

    private _cameraApprovalDialogAlertTimeout = 30;

    private _suppressVisibilityChanged = false;

    private _tsTermsAndConditions: any;

    private _inCameraApprovalDialogAlertTimer: any;

    private _userConnectionUniqueId = 0;

    private _roomInfo: IRoom | null = null;

    constructor(
        private platformType: PlatformType,
        private _browserUtilsService: IBrowserUtilsService,
        private _eventService: ITsEventService,
        _networkInfo: ITsNetworkInfo,
        private _visibilityChange: IVisibilityChange,
        private _roomsService: IRoomService,
        _browserInfo: any
    ) {
        super(CLIENT_VERSION, platformType, trace.info, SOCKET_FORCE_EMIT);
        this._networkInfo = _networkInfo;
        this._browserInfo = _browserInfo;
        trace.info(`constructor: CLIENT_VERSION=${CLIENT_VERSION}  SOCKET_FORCE_EMIT=${SOCKET_FORCE_EMIT}`);

        this.fsEnabled = false;

        window.addEventListener('online', () => {
            this.connectInternal();
        });
    }

    private _setClientUniqueId() {
        this._userConnectionUniqueId = this._browserUtilsService!.getFromSessionStorage('_userConnectionUniqueId');

        if (!this._userConnectionUniqueId) {
            this._userConnectionUniqueId = Date.now().valueOf();
            this._browserUtilsService!.saveToSessionStorage('_userConnectionUniqueId', this._userConnectionUniqueId);
        }
    }

    private _subscribeSocketEvents() {
        this.onResourcesUpdated((message) => this.preEmit('resourcesUpdated', message));
        this.onICECredentialsChanged((message) => this._handleICECredentialsChange(message));
        this.onRoomCode((roomCode) => this._onRoomCode(roomCode));
        this.onException((message) => this.emitOnException(message));
        this.onJoinRoom((roomId) => this.preEmit('joinRoom', roomId));
        this.onReceiveMessage((eventArgs) => this._receiveMessage(eventArgs));
        this.onStatusChanged((eventArgs) => this._statusChanged(eventArgs));
        this.onPerformAction((message) => this.performAction(message));
        this.onSync((message) => this._sync(message));
    }

    private _onRoomCode(roomCode: string) {
        this.setRoomCode(roomCode);
        this.preEmit('roomCode', roomCode);
    }

    private _receiveMessage(msg: any) {
        if (this.wasMessageIsAlreadyHandled(msg)) {
            return;
        }

        if (msg.data.type === 'log') {
            this.preEmit('log', msg.data.message, msg.sender);
        } else {
            this.preEmit('message', msg);
        }
    }

    private _statusChanged(message: any) {
        if (!this.roomId || !this.synced) {
            return;
        }

        if (message.param === 'observer') {
            this.preEmit('observer', message.value);
        }

        this.updateStatus(message);
    }

    private _handleICECredentialsChange(message: any) {
        if (this.accountSettings.mediaServiceType === 'MEDIA_SERVER') {
            this.accountSettings.mediaServerConfig = message;
        } else if (this.accountSettings.mediaServiceType === 'TURN_SERVER') {
            this.accountSettings.turnServerConfig = message;
        }

        this.preEmit('ICECredentialsChanged', message);
    }

    private _subscribeSocketConnectivity() {
        this.onConnected(this.onSocketConnectedHandler);
        this.onDisconnected(this.onSocketDisconnectedHandler);
        this.onConnectionResumed(this.onSocketConnectionResumed);
        this.onConnectionInterrupted(this.onSocketConnectionInterrupted);
    }

    set temporaryRoomCode(roomCode) {
        this._browserUtilsService!.saveToSessionStorage('roomCode', roomCode);
    }

    get temporaryRoomCode() {
        return this._browserUtilsService!.getFromSessionStorage('roomCode');
    }

    get dashboardVideoResolution() {
        if (!this.dashboard.allowHD) {
            return DEFAULT_VIDEO_RESOLUTION;
        }

        return this.accountSettings.videoResolution || DEFAULT_VIDEO_RESOLUTION;
    }

    get roomInfo(): IRoom | null {
        return this._roomInfo;
    }

    init(tsTermsAndConditions: any) {
        this._tsTermsAndConditions = tsTermsAndConditions;
        this._setClientUniqueId();
        this.initSocket();
        this._subscribeSocketConnectivity();
        this._subscribeSocketEvents();

        this._visibilityChange.visible(() => {
            this.visibilityChanged(true);
        });

        this._visibilityChange.hidden(() => {
            this.visibilityChanged(false);
        });
    }

    /*
     * Try to connect to a room. The service supports one connection
     * at a time, only.
     *
     * @param room {String} room uid
     * @param userType {String}
     * @param roomCode {String} room short code. Optional parameter
     */
    connect(roomId: string, userType: UserType, roomCode?: string, platformType?: PlatformType) {
        this.setUserType(userType);

        return this.connectToRoom(
            platformType || PlatformType.mobile_web,
            roomId,
            roomCode,
            undefined,
            this._userConnectionUniqueId as unknown as string
        );
    }

    /*
     * Disconnect from current chat-room, if connected
     */
    disconnect(skipRoomEnd: boolean) {
        if (!this.offlineRoom && this.roomId && !skipRoomEnd) {
            this._roomsService
                .endRoom(this.roomId, {closedBy: ClosedByTypes.mobile})
                .catch((e) => trace.error('disconnect: ', e));
        }

        if (this.accountSettings) {
            this.fsEnabled = this.accountSettings.allowJoinByMeetingCode;
            this.redirectUrl = this.accountSettings.redirectUrl;
            this.redirectType = this.accountSettings.redirectType;
            this.keepCustomerMobile = this.accountSettings.keepCustomerMobile;
            this.customerSurveyEnabled = this.accountSettings.customerSurveyEnabled;
            this.customerSurveyMaxFeedbackRating = this.accountSettings.customerSurveyMaxFeedbackRating;
        }

        this._suppressVisibilityChanged = false;
        this.offlineRoom = false;

        this.disconnectSocket();

        return Promise.resolve();
    }

    notifyNewHistoryMessage(newMessageData: any) {
        this.preEmit('NEW_HISTORY_MESSAGE_RECEIVED', newMessageData);
    }

    /**
     * Request room starting
     */
    requestRoomCode(roomCode: string, user: any) {
        return this.requestSocketRoomCode(roomCode, user);
    }

    /**
     * Upload media (image/video).
     * While this function doesn't communicate directly with the chat server,
     * it's part of the chat functionality we want exposed to the frontend.
     *
     * @param media - ObjectUrl that we will upload to the server
     * @param isVideo - Boolean - true if the media is a video
     * @param fileName - String filename that will be used for the upload
     */
    uploadRoomMedia(media: any, isVideo: boolean, fileName: string) {
        if (!this.roomId) {
            return Promise.reject({error: 'No roomId', failurePhase: 'Before upload', mediaName: fileName});
        }

        const roomUploadCb = (id: string, payload: any) => this._roomsService.getHistoryUploadUrl(id, payload);

        return this.uploadMediaToS3(media, isVideo, fileName, roomUploadCb);
    }

    /**
     * Uploads media (image/video) using a signed S3 link and a custom signing function
     *
     * @param media - Media ObjectURL (one use, revoked in the function)
     * @param isVideo - true if the media is a video
     * @param fileName - String filename that will be used for the upload
     * @param cbSign - Signing callback:(resourceId, payload)
     *                  payload has { fileName, fileType, fileSize }
     */
    uploadMediaToS3(media: any, isVideo: boolean, fileName: string, cbSign: any) {
        return new Promise((resolve, reject) => {
            this._generateMediaFileName(media, isVideo, fileName, (blob, mediaName) => {
                const imageUploadTimeout = get(this.accountSettings, 'imageUploadTimeoutSeconds');
                const mediaFileSizeInKB = convertToSizeString(blob.size);

                if (!isVideo) {
                    this._eventService!.sendEventLog(
                        'none',
                        this.roomId || 'none',
                        STATUS_MESSAGES.CUSTOMER_IMAGE_UPLOAD_STARTED,
                        {mediaFileName: mediaName, ImageSize: mediaFileSizeInKB}
                    );
                }

                const signingData = {fileName: mediaName, fileType: blob.type, fileSize: blob.size};

                return cbSign(this.roomId, signingData)
                    .then((response: any) => {
                        const url = response.signedRequest,
                            publicUrl = response.publicUrl;

                        getRootStore()
                            .restApiService.put(url, blob, {
                                headers: {
                                    'Content-Type': blob.type
                                },
                                timeout: imageUploadTimeout * 1000 // Timeout in milliseconds
                            })
                            .then(() => {
                                if (!isVideo) {
                                    this._eventService!.sendEventLog(
                                        'none',
                                        this.roomId || 'none',
                                        STATUS_MESSAGES.CUSTOMER_IMAGE_UPLOAD_SUCCESS,
                                        {mediaFileName: mediaName, ImageSize: mediaFileSizeInKB}
                                    );
                                }

                                resolve({mediaUrl: publicUrl, mediaName: mediaName});
                            })
                            .catch((error) => {
                                reject({
                                    url: url,
                                    status: error.response?.status || '',
                                    error: error.message || error,
                                    failurePhase: 'Uploading to S3',
                                    mediaName
                                });
                            });
                    })
                    .catch((error: Error) => {
                        reject({error, failurePhase: 'Image Signing', mediaName});
                    });
            });
        });
    }

    _sync(message: any) {
        if (!this.roomId) {
            return;
        }

        this.synced = true;

        const room = message.room,
            initialStatus = message.initialStatus;

        if (this._networkInfo!.connectionType !== 'none') {
            this.setRoomNetworkInfo(
                this._networkInfo!.connectionType,
                (this._networkInfo as ITsNetworkInfo)!.downlinkMax
            );
        }

        const newMode = get(room, 'status.client.mode');
        const reload = get(room, 'status.client.reloadWhenVideoFailed');

        if (reload) {
            this.preEmit('reloadWhenVideoFailed', true, get(this.client, 'reloadWhenVideoFailed'));
        }

        if (newMode !== get(this.client, 'mode')) {
            this.preEmit('clientMode', newMode);

            this._tsTermsAndConditions.abort(this);
        }

        this.setAccountSettings(this._replaceUrlPlaceholder(room.accountSettings));
        this.setRoomStatus(room.status);

        this.offlineRoom = room.offline;
        this._cameraApprovalDialogAlertTimeout = get(this.accountSettings, 'cameraApprovalDialogAlertTimeout');
        this.imageSharingQuality = get(this.accountSettings, 'imageSharingQuality') || undefined;
        this.enableSilentUploadTest = get(this.accountSettings, 'enableSilentUploadTest') || false;
        this.enableSharing = get(this.accountSettings, 'enableSharing') || false;
        this.redirectDelayTime = get(this.accountSettings, 'redirectDelayTime');
        this.customerSurveyEnabled = get(this.accountSettings, 'customerSurveyEnabled');
        this.customerSurveyMaxFeedbackRating = get(this.accountSettings, 'customerSurveyMaxFeedbackRating');

        this.drainAllQueues();

        forEach(toPairs(this.dashboard), (pair) => {
            const param = pair[0],
                oldValue = initialStatus[UserType.dashboard][param],
                newValue = pair[1];

            if (!isEqual(oldValue, newValue)) {
                this.preEmit(this.eventName(param, UserType.dashboard), param, newValue, oldValue);
            }
        });

        forEach(room.messages, (msg) => {
            if (this.wasMessageIsAlreadyHandled(msg)) {
                return;
            }

            if (msg.data.private && msg.sender !== UserType.client) {
                return;
            }

            if (msg.data.type === 'log') {
                this.preEmit('log', msg.data.message, msg.sender);
            } else {
                this.preEmit('message', msg);
            }
        });

        this.preEmit('sync', room.accountSettings);
    }

    //FIXME: chatApi has not been initialized at this point. need to pass as a callback when onJoinRoom will be implement
    setRoomNetworkInfo(connectionType: string, downlinkMax: any, roomId?: string) {
        if (!this._networkInfo!.lastConnectionType || this._networkInfo!.lastConnectionType !== connectionType) {
            (this._networkInfo as ITsNetworkInfo)!.setLastConnectionType(connectionType);
            this.socketEmit('mobileNetworkType', {connectionType, downlinkMax});

            this._roomsService.setNetworkInfo(roomId || this.roomId || '', connectionType, downlinkMax).then((room) => {
                this._roomInfo = room;
            });
        }
    }

    cameraApprovalDialogStateChange(state: boolean) {
        trace.info('cameraApprovalDialogStateChange: ', state);

        if (this._cameraApprovalDialogAlertTimeout) {
            if (this._inCameraApprovalDialogAlertTimer && !state) {
                clearTimeout(this._inCameraApprovalDialogAlertTimer);
                this._inCameraApprovalDialogAlertTimer = null;
            } else if (!this._inCameraApprovalDialogAlertTimer && state) {
                this._inCameraApprovalDialogAlertTimer = setTimeout(() => {
                    this.sendLog(STATUS_MESSAGES.CAMERA_DIALOG_IDLE_ALERT);
                    this._eventService!.sendEventLog(
                        'none',
                        this.roomId || 'none',
                        STATUS_MESSAGES.CAMERA_DIALOG_IDLE_ALERT,
                        {timeout: this._cameraApprovalDialogAlertTimeout}
                    );
                    this._inCameraApprovalDialogAlertTimer = null;
                }, this._cameraApprovalDialogAlertTimeout * 1000);
            }
        }

        trace.info('changing inCameraApprovalDialog to ', state);

        this.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IN_CAMERA_APPROVAL_DIALOG, state);
    }

    _handleMustBeSentFlag(param: string) {
        const mustBeSentCriteriaList = [
            socketEvents.CLIENT_OUT_SET_STATUS.IN_CAMERA_APPROVAL_DIALOG,
            socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS,
            socketEvents.CLIENT_OUT_SET_STATUS.TOS_ACCEPTED,
            socketEvents.CLIENT_OUT_SET_STATUS.TOS_REJECTED,
            socketEvents.CLIENT_OUT_SET_STATUS.PREPARING,
            socketEvents.CLIENT_OUT_SET_STATUS.VIDEO_HANDSHAKE_SUCCESS,
            socketEvents.CLIENT_OUT_SET_STATUS.VIDEO_SUPPORT
        ];

        return mustBeSentCriteriaList.includes(param);
    }

    setStatus(param: string, value: any, silent = false) {
        const mustBeSent = this._handleMustBeSentFlag(param);

        return this.emitChangeStatus(param, value, UserType.client, silent, mustBeSent);
    }

    suppressVisibilityChanged() {
        this._suppressVisibilityChanged = true;
    }

    generateMediaFileName(media: string, isVideo: boolean, fileName: string | null) {
        return new Promise((resolve) => {
            this._generateMediaFileName(media, isVideo, fileName, (blob, mediaFileName) =>
                resolve({blob, mediaFileName})
            );
        });
    }

    _generateMediaFileName(media: string, isVideo: boolean, fileName: any, cb: (blob: any, mediaName: string) => void) {
        this.imageToBlob(media, (blob) => {
            const ext = blob.type.match(isVideo ? /video\/(.*)/i : /image\/(.*)/i);
            const mediaFileName = fileName || `${Date.now()}-client` + (ext && ext[1] ? `.${ext[1]}` : '');

            return cb(blob, mediaFileName);
        });
    }

    _replaceUrlPlaceholder(settings: any) {
        const currentSubdomain = window.location.host.split('.')[0];

        if (settings.termsURL) {
            const domainZoneSuffix = get(settings, 'domainZoneSuffix');
            const urlToReplace = includes(settings.termsURL, domainZoneSuffix)
                ? `https://{{subdomain}}.${domainZoneSuffix}.techsee.me`
                : 'https://{{subdomain}}.techsee.me';

            settings.termsURL = settings.termsURL.replace(
                urlToReplace,
                getFrontendUrl(currentSubdomain, null, domainZoneSuffix)
            );
        }

        return settings;
    }

    sendSwitchModeRequest(failureCause: FailureTriggerAction) {
        if (![FailureTriggerAction.clientNetworkIssue, FailureTriggerAction.unableToPublish].includes(failureCause)) {
            throw new Error('Not supported ' + failureCause);
        }

        this.socketEmit('switchMode', {
            trigger: {
                reason: SwitchModeReason.failure,
                failureCause: failureCause
            }
        });
    }

    incrementCounter(counterType: any, message: any) {
        this.socketEmit('incrementCounter', {counterType, message});
    }

    /*
     * Send an image (wrapper function to sendMessage)
     *
     * @param url {String} image to upload
     */
    sendImage(url: string, meta: any) {
        if (!this.connected) {
            this.onReady(() => {
                this._eventService!.sendEventLog('none', this.roomId || 'none', STATUS_MESSAGES.IMAGE_SENDING_DELAYED, {
                    side: PlatformType.mobile_web,
                    url,
                    meta
                });
            });
        }

        return this.sendMessage('image', url, meta)
            .then(() => this.preEmit('imageSent'))
            .catch((err) => {
                this.onReady(() => {
                    this._eventService!.sendEventLog(
                        'none',
                        this.roomId || 'none',
                        STATUS_MESSAGES.IMAGE_SENDING_FAILED,
                        {side: PlatformType.mobile_web, url, meta, err}
                    );
                });

                throw err;
            });
    }

    imageLoadStarted(isNew: any, type: any, src: string) {
        if (isNew && type === 'IMG-DB') {
            this._eventService!.sendEventLog(
                'none',
                this.roomId || 'none',
                STATUS_MESSAGES.CUSTOMER_IMAGE_DOWNLOAD_STARTED,
                {mediaFileName: src}
            );
        }
    }

    imageLoadFailed(isNew: any, type: any, src: string, err: any) {
        if (isNew && type === 'IMG-DB') {
            this._eventService!.sendEventLog(
                'none',
                this.roomId || 'none',
                STATUS_MESSAGES.CUSTOMER_IMAGE_DOWNLOAD_FAILED,
                {
                    mediaFileName: src,
                    err
                }
            );
        }
    }

    imageLoaded(isNew: any, type: any, src: string) {
        if (isNew && type === 'IMG-DB') {
            this._eventService!.sendEventLog(
                'none',
                this.roomId || 'none',
                STATUS_MESSAGES.CUSTOMER_IMAGE_DOWNLOAD_SUCCESS,
                {mediaFileName: src}
            );
        }
    }

    visibilityChanged(state: any) {
        if (!this._suppressVisibilityChanged) {
            this.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.VISIBLE, state);
        }

        this._suppressVisibilityChanged = false;
    }
}
